/* eslint-disable prefer-destructuring */
import './list.scss';
import dataApi from '@/api/member/member.js';
/*
 * 会员管理
 *  */
export default {
  name: 'member-list',
  components: {},
  data() {
    return {
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100],
        currentPage: 1
      },
      memberList: [],
      activeName: 'baby',
      drawerVisible: false,
      dialogVisible: false,
      sendTplDialogTitle: '发送模板消息',
      sendTplDialogVisible: false,
      expiresDialogVisible: false,
      referrerInputTabel: {
        column: {
          children: {
            border: true,
            column: [
              {
                label: '编号',
                prop: 'id',
                width: 80
              },
              {
                label: '会员名称',
                search: true,
                searchSpan: 24,
                prop: 'nickname',
                align: 'left'
              }
            ]
          }
        },
        props: {
          label: 'nickname',
          value: 'id'
        }
      },
      tableData: [],
      tbleOption: {
        total: 20,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        addBtn: false,
        ...this.$store.getters.tableConfig,
        delBtn: false,
        editBtn: false,
        selection: false,
        column: [
          {
            label: '编号',
            prop: 'id',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '名称',
            prop: 'name',
            type: 'input',
            search: true
          },
          {
            type: 'select',
            label: '会员组',
            span: 12,
            display: true,
            prop: 'member_group_id',
            dicFlag: true,
            filterable: true,
            search: true,
            dicUrl: `${this.$baseUrl}general/get_member_group`,
            dicMethod: 'get'
          },
          {
            type: 'select',
            label: '会员级别',
            span: 12,
            display: true,
            prop: 'member_rank_id',
            dicFlag: true,
            filterable: true,
            search: 'true',
            dicUrl: `${this.$baseUrl}general/get_member_rank`,
            dicMethod: 'get'
          },
          {
            label: '公众号',
            type: 'radio',
            prop: 'is_subscribe',
            value: 0,
            search: true,
            dicData: [
              {
                label: '已订阅',
                value: 1
              },
              {
                label: '未订阅',
                value: 0
              }
            ]
          },
          {
            label: '积分',
            prop: 'points',
            type: 'input',
            editDisplay: false
          },
          {
            label: '能量值',
            prop: 'energy',
            type: 'input',
            editDisplay: false
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: -1
              },
              {
                label: '启用',
                value: 1
              }
            ],
            display: true,
            search: 'true',
            prop: 'status'
          },
          {
            // 创建时间 create_time
            label: '注册时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 更新时间 update_time
            label: '更新时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_time'
          },
          {
            label: '注册日期',
            prop: 'datetime',
            hide: true,
            span: 12,
            search: true,
            searchslot: true
          }
        ]
      },
      searchData: {
        datetime: []
      },
      childMap: {
        is_child: 1,
        member_id: 0,
        type: 'baby'
      },
      childPage: {
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50, 100],
        currentPage: 1
      },
      babyOption: {
        align: 'center',
        addBtn: false,
        viewBtn: false,
        editBtn: true,
        delBtn: true,
        searchMenuPosition: 'left',
        filterBtn: false,
        ...this.$store.getters.tableConfig,
        searchMenuSpan: 9,
        column: [
          {
            label: '宝宝名称',
            prop: 'name'
          },
          {
            label: '性别',
            prop: 'sex',
            type: 'radio',
            dicData: [
              {
                label: '保密',
                value: 0
              },
              {
                label: '男',
                value: 1
              },
              {
                label: '女',
                value: 2
              }
            ]
          },
          {
            label: '过敏源',
            prop: 'allergy'
          },
          {
            label: '身份证',
            prop: 'idcard'
          },
          {
            label: '备注',
            prop: 'extend'
          },
          {
            label: '出生日期',
            prop: 'birthday'
          },
          {
            label: '添加时间',
            prop: 'create_time'
          }
        ]
      },
      childColumn: [
        [
          {
            label: '类型',
            type: 'radio',
            dicData: [
              {
                label: '充值',
                value: 1
              },
              {
                label: '扣减',
                value: 2
              },
              {
                label: '扣减-充值退款',
                value: 3
              }
            ],
            prop: 'type'
          },
          {
            label: '来源',
            type: 'radio',
            dicData: [
              {
                label: '订单使用',
                value: 1
              },
              {
                label: '订单取消',
                value: 2
              },
              {
                label: '管理员更新',
                value: 3
              },
              {
                label: '签到',
                value: 4
              },
              {
                label: '积分商城使用',
                value: 5
              },
              {
                label: '赠送',
                value: 6
              },
              {
                label: '取消兑换退还',
                value: 8
              },
              {
                label: '活动签到',
                value: 9
              },
              {
                label: '充值退款',
                value: 10
              }
            ],
            prop: 'source_type'
          },
          {
            label: '数量',
            prop: 'number',
            slot: true
          },
          {
            label: '备注',
            prop: 'remark'
          },
          {
            label: '有效期',
            prop: 'expires_time',
            type: 'date',
            format: 'yyyy-MM-dd'
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '无效',
                value: -1
              },
              {
                label: '有效',
                value: 1
              }
            ],
            display: true,
            prop: 'status'
          },
          {
            // 创建者 create_by
            label: '创建者',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_user'
          },
          {
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ],
        [
          {
            label: '签到日期',
            prop: 'signin_date',
            search: 'true',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd'
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '无效',
                value: -1
              },
              {
                label: '有效',
                value: 1
              }
            ],
            display: true,
            prop: 'status'
          },
          {
            label: '签到时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ]
      ],
      childOption: {
        total: 10,
        align: 'center',
        ...this.$store.getters.tableConfig,
        card: false,
        menu: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        filterBtn: false,
        selection: false,
        column: []
      },
      childData: [],
      formDa: {},
      formOp: {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '关闭',
        menuPosition: 'center',
        tabs: false,
        column: [
          {
            label: '会员名称',
            prop: 'name',
            type: 'input',
            search: true,
            addDisplay: true,
            editDisplay: true,
            required: true,
            rules: [
              {
                required: true,
                message: '会员名称不能为空'
              }
            ]
          },
          {
            type: 'select',
            label: '会员组',
            span: 12,
            display: true,
            prop: 'member_group_id',
            dicFlag: true,
            filterable: true,
            search: true,
            dicUrl: `${this.$baseUrl}general/get_member_group`,
            dicMethod: 'get'
          },
          {
            type: 'select',
            label: '会员级别',
            span: 12,
            display: true,
            prop: 'member_rank_id',
            dicFlag: true,
            filterable: true,
            search: 'true',
            dicUrl: `${this.$baseUrl}general/get_member_rank`,
            dicMethod: 'get'
          },
          {
            label: '手机号',
            prop: 'mobile',
            type: 'input',
            search: true,
            span: 12,
            editDisplay: true,
            required: true,
            rules: [
              {
                required: true,
                message: '手机号不能为空'
              }
            ]
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '禁用',
                value: 0
              },
              {
                label: '启用',
                value: 1
              }
            ],
            display: true,
            search: 'true',
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true,
            span: 12
          }
        ]
      },
      formTplMsgDa: {},
      formTplMsgOp: {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: false,
        emptyText: '清空',
        menuPosition: 'center',
        tabs: false,
        column: []
      },
      formTplColumn: [
        {
          label: '标题',
          prop: 'first',
          span: 24,
          type: 'input',
          required: true,
          rules: [
            {
              required: true,
              message: '内容不能为空'
            }
          ]
        },
        {
          label: '信息类别',
          prop: 'keyword1',
          type: 'input',
          span: 24,
          required: true,
          rules: [
            {
              required: true,
              message: '内容不能为空'
            }
          ]
        },
        {
          label: '信息名称',
          prop: 'keyword2',
          type: 'input',
          span: 24,
          required: true,
          rules: [
            {
              required: true,
              message: '内容不能为空'
            }
          ]
        },
        {
          label: '信息简介',
          prop: 'keyword3',
          type: 'input',
          span: 24,
          required: true,
          rules: [
            {
              required: true,
              message: '内容不能为空'
            }
          ]
        },
        {
          label: '备注',
          prop: 'remark',
          type: 'textarea',
          span: 24,
          required: true,
          rules: [
            {
              required: true,
              message: '内容不能为空'
            }
          ]
        },
        {
          label: '跳转页面',
          prop: 'url',
          type: 'input',
          span: 24
        }
      ],
      formPointColumn: [
        {
          type: 'select',
          label: '会员',
          span: 24,
          display: true,
          props: {
            label: 'label',
            value: 'value'
          },
          dicUrl: `${this.$baseUrl}general/get_member?keyword={{key}}`,
          remote: true,
          dicMethod: 'post',
          prop: 'member_id',
          required: true,
          rules: [
            {
              required: true,
              message: '会员必须填写'
            }
          ]
        },
        {
          type: 'radio',
          label: '类型',
          dicData: [
            {
              label: '充值',
              value: 1
            },
            {
              label: '扣减',
              value: 2
            }
          ],
          span: 12,
          display: true,
          props: {
            label: 'label',
            value: 'value'
          },
          prop: 'type',
          value: 1,
          required: true,
          rules: [
            {
              required: true,
              message: '类型必须填写'
            }
          ]
        },
        {
          type: 'number',
          label: '数量',
          controls: true,
          span: 12,
          display: true,
          prop: 'number',
          required: true,
          rules: [
            {
              required: true,
              message: '排序必须填写'
            }
          ],
          value: 0
        },
        {
          label: '备注',
          prop: 'remark',
          type: 'textarea',
          span: 24
        }
      ],
      member: {},
      expiresOp: {
        disabled: false,
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 110,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: false,
        emptyText: '清空',
        menuPosition: 'center',
        tabs: false,
        column: [
          {
            label: '流水号',
            prop: 'source_id',
            disabled: true
          },
          {
            label: '数量',
            prop: 'number',
            disabled: true
          },
          {
            label: '有效期',
            prop: 'expires_time',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'timestamp',
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < Date.now();
              }
            }
          }
        ]
      },
      expiresDa: []
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    syncData() {
      this.$confirm('是否同步数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          dataApi.sync_data.r().then((res) => {
            this.$message({
              message: '同步成功',
              type: 'success'
            });
            console.log(res);
          });
        })
        .catch(() => {
          this.$message({
            message: '已取消',
            type: 'info'
          });
        });
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    searchExcel() {
      const postData = {
        ...this.searchData
      };
      dataApi.exportFile.r(postData).then((res) => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: false
        }).then(() => {
          window.location.href = res.data;
        });
      });
    },
    // 添加
    handleSubmit(form, done, loading) {
      console.log('form', form, loading);
      dataApi.add.r({ ...form, dict_id: 0 }).then((res) => {
        console.log(res);
        done(form);
        loading();
      });
      done();
    },
    drawerClose() {
      this.activeName = '1';
      this.childData = [];
      this.childPage.currentPage = 1;
      this.childMap = {
        is_child: 1,
        member_id: 0,
        type: 'baby'
      };
      console.log(this.formDa);
      this.drawerVisible = false;
    },
    dialogCloseCallback() {
      this.productList = [];
      this.bankList = [];
    },
    referrerOnLoad({ page, value, data }, callback) {
      // 首次加载去查询对应的值
      if (value) {
        const postData = {
          id: value
        };
        dataApi.getOne.r(postData).then((res) => {
          callback({
            ...res.data
          });
        });
        return;
      }
      const postData = {
        page: page.currentPage,
        pageSize: page.pageSize,
        ...data
      };
      dataApi.getList.r(postData).then((res) => {
        if (page.currentPage === 1) {
          res.data.list.unshift({
            id: -1,
            nickname: '自助注册'
          });
        }

        callback({
          total: res.data.total,
          data: res.data.list
        });
      });
    },
    referrerFormatter(row) {
      if (!row.nickname) return '';
      return `${row.nickname}`;
    },
    sendTplMsgDialog(e) {
      if (e === 'sendTpl') {
        // 发送模板消息
        this.formTplMsgDa.action = 'sendtpl';
        this.formTplMsgOp.column = this.formTplColumn;
        this.sendTplDialogTitle = '发送模板消息';
      }
      if (e === 'points') {
        // 积分操作
        this.formTplMsgDa.action = 'points';
        this.formTplMsgOp.column = this.formPointColumn;
        this.sendTplDialogTitle = '更新会员积分';
      }
      if (e === 'energy') {
        // 能量值操作
        this.formTplMsgDa.action = 'energy';
        this.formTplMsgOp.column = this.formPointColumn;
        this.sendTplDialogTitle = '更新会员能量值';
      }

      this.sendTplDialogVisible = true;
    },
    sendTplMsg(form, done) {
      if (form.action === 'sendtpl') {
        // 发送模板消息
        dataApi.sendTplMsg
          .r(form)
          .then(() => {
            this.sendTplDialogVisible = false;
            this.$refs.sendForm.resetForm();
            done(form);
            this.$message.success('操作成功~');
          })
          .catch(() => {
            done(form);
          });
      } else {
        dataApi.updatePonintEnery
          .r(form)
          .then(() => {
            this.sendTplDialogVisible = false;
            this.$refs.sendForm.resetForm();
            done();
            this.getList();
            this.$message.success('操作成功~');
          })
          .catch(() => {
            done();
          });
      }
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将停用该用户,用户将无法登陆, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        dataApi.del.r({ id: form.id }).then(() => {
          this.getList();
          this.$message.success('停用成功');
        });
      });
    },
    // 修改
    rowUpdate(form, done) {
      dataApi.update
        .r(form)
        .then((res) => {
          console.log(res);
          this.getList();

          this.dialogVisible = false;
        })
        .catch(() => {});
      done(form);
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    },
    rowEdit(row) {
      this.dialogVisible = true;
      this.formDa = row;
      this.memberList = [
        {
          id: row.referrer,
          nickname: row.referrer_text
        }
      ];
      if (row.referrer > 0) {
        this.memberList.push({
          id: 0,
          nickname: '自助注册'
        });
      }
    },
    getMember(query) {
      const postData = {
        nickname: query,
        page: 1,
        pageSize: 10
      };
      dataApi.getList.r(postData).then((res) => {
        this.memberList = res.data.list;
        this.memberList.push({
          id: 0,
          nickname: '自助注册'
        });
      });
    },
    rowView(row) {
      this.childMap = {
        is_child: 1,
        member_id: row.id,
        type: 'baby'
      };
      this.getInfo(row);
      this.drawerVisible = true;
    },
    getInfo(row) {
      dataApi.getOne.r({ id: row.id }).then((res) => {
        this.member = res.data;
      });

      this.getChildData(row.id);
    },
    memberChangeSelect() {},
    // 团队切换
    handleTabClick(tab) {
      if (tab.name !== this.childMap.type) {
        this.childMap.type = tab.name;
        this.getChildData();
      }
    },
    emptytChange() {
      this.dialogVisible = false;
    },
    getChildData() {
      let column = [];
      // if (this.childMap.type === 'baby') {
      //   column = this.childColumn[0];
      // }
      if (this.childMap.type === 'energy' || this.childMap.type === 'points') {
        column = this.childColumn[0];
      }
      if (this.childMap.type === 'signin') {
        column = this.childColumn[1];
      }
      this.childOption.column = column;
      const postData = {
        page: this.childPage.currentPage,
        pageSize: this.childPage.pageSize,
        ...this.childMap
      };
      dataApi.getChild.r(postData).then((res) => {
        this.childData = res.data.list;
        this.childPage.total = res.data.total;
      });
    },
    upEnergyExpires(row) {
      this.expiresDialogVisible = true;
      if (!row.expires_time) {
        row.expires_time = Date.now();
      }
      this.expiresDa = row;
    },
    submitExpires(form, done) {
      dataApi.up_energy_expires
        .r(form)
        .then(() => {
          this.expiresDialogVisible = false;
          this.$refs.upExpiresForm.resetForm();
          done(form);
          this.$message.success('操作成功~');
        })
        .catch(() => {
          done(form);
        });
    },
    dialogCloseExpires() {
      this.expiresDialogVisible = false;
    }
  }
};
